const CTA = () => {

    return (
        <>

            <section class="row ctaSec" style={{ opacity: '100%', color: 'white' }}>
                <div class="col-lg-12 ctaDiv" style={{ padding: '4%' }}>
                    <p style={{ opacity: '80%' }}>CONATCT US NOW</p>
                    <h2 class="ctaHeading">
                        <font class="gradientText">Building Digital Legacies: </font>Embrace Zeegens's Expertise
                    </h2>
                    <p class="ctaPara">Ready to elevate your online presence? Let our expert team create a stunning website that
                        captivates your audience and drives business success.
                    </p>
                    <a href="#contact"><button class="csBtn ctaBtn" style={{ color: 'black' }}>GET STARTED TODAY</button></a>
                </div>
            </section>
        </>
    );
}

export default CTA;
