import { CiCalendar } from "react-icons/ci";
import { FaCode } from "react-icons/fa";
import { FiCalendar } from "react-icons/fi";
import { MdOutlineReviews } from "react-icons/md";

const Process = () => {

    return (
        <>
            <section class="row processSec" id="process">
                <h2 class="processHeading" style={{ opacity: '100%', color: 'white' }}>
                    <font class="gradientText">Our Seamless Process:</font> Transforming Visions into Reality
                </h2>
                <div class="row processInnerSec" style={{ opacity: '100%', color: 'white' }}>
                    <div class="col-lg-3 processDiv">
                        <div class="icon icon-1"><FiCalendar style={{ color: 'black', fontSize: 25 }} /></div><br />
                        <p style={{ opacity: '60%', fontSize: 14 }}>STEP-1</p>
                        <h4>Consultation</h4>
                        <p style={{ opacity: '70%' }}>Discuss your goals, needs, and vision for your website with our experts.
                        </p>
                    </div>
                    <div class="col-lg-3 processDiv">
                        <div class="icon icon-2"><FaCode style={{ color: 'black', fontSize: 25 }} /></div><br />
                        <p style={{ opacity: '60%', fontSize: 14 }}>STEP-2</p>
                        <h4>Development</h4>
                        <p style={{ opacity: '70%' }}>Our team brings creativity and technical prowess to craft your dream website.
                        </p>
                    </div>
                    <div class="col-lg-3 processDiv">
                        <div class="icon icon-3"><MdOutlineReviews style={{ color: 'black', fontSize: 25 }} /></div><br />
                        <p style={{ opacity: '60%', fontSize: 14 }}>STEP-3</p>
                        <h4>Review &hLauncg</h4>
                        <p style={{ opacity: '70%' }}>We collaborate with you, refining every detail until perfection is achieved.
                        </p>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Process;
