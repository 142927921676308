import React from 'react';

const servicesData = [
    {
        title: "Web App Development",
        description: `Our web development expertise ensures lightning-fast loading times, offering your visitors a seamless and 
        engaging user experience. Speed up your website with Zeegens.`,
    },
    {
        title: "Mobile App Development",
        description: `Our commitment doesn't end with a website launch. We offer continuous support and updates to keep your 
        website thriving and up-to-date.`,
    },
    {
        title: "E-commerce Website",
        description: `We don't believe in one-size-fits-all. Our team crafts customized websites that align perfectly with your 
        unique brand identity and business goals.`,
    },
    {
        title: "Business Websites",
        description: `Our web development expertise ensures lightning-fast loading times, offering your visitors a seamless and 
        engaging user experience. Speed up your website with Zeegens.`,
    },
    {
        title: "Custom App Solutions",
        description: `Our commitment doesn't end with a website launch. We offer continuous support and updates to keep your 
        website thriving and up-to-date.`,
    },
    {
        title: "WordPress & Shopify",
        description: `We don't believe in one-size-fits-all. Our team crafts customized websites that align perfectly with your 
        unique brand identity and business goals.`,
    },
];

const Services = () => {
    return (
        <>
            <section className="row featuresSec" id="services" style={{ opacity: '100%', color: 'white' }}>
                <div className="row">
                    <center>
                        <h2 className="csHeading">Our Services: Your One-Stop Tech Solutions</h2>
                    </center>
                </div>
                {servicesData.map((service, index) => (
                    <div className="col-lg-4" key={index} style={{ margin: '3% 0' }}>
                        <div className="subFeaturesDiv">
                            <h4>{service.title}</h4>
                            <p style={{ opacity: '70%', margin: 0 }}>{service.description}</p>
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
}

export default Services;