import React from 'react';

const portfolioData = [
    {
        title: "DilSey",
        link: '',
        img: '/images/dilsey.png'
    },
    {
        title: "Nivishka Services",
        link: '',
        img: '/images/nivishkacom.png'
    },
    {
        title: "Nivishka Services",
        link: '',
        img: '/images/nivishkain.png'
    },
    {
        title: "MilkPromise",
        link: '',
        img: '/images/milkpromise.png'
    },
    {
        title: "Nivishka PMS",
        link: '',
        img: '/images/pms.png'
    },
    {
        title: "Oredo Motor",
        link: '',
        img: '/images/motor.png'
    },
    // {
    //     title: "Concorde Neo",
    //     link: '',
    //     img: '/images/concorde.png'
    // },
];

const Portfolio = () => {
    return (
        <>
            <section className="row featuresSec" id="services" style={{ opacity: '100%', color: 'white', background: '#000', padding: '2% 5%' }}>
                <div className="row">
                    <center>
                        <h2 className="csHeading" style={{ marginBottom: '3%' }}>Portfolio</h2>
                    </center>
                </div>
                {portfolioData.map((item, index) => (
                    <div className="col-lg-4" key={index} style={{ margin: '1% 0' }}>
                        <div className="subFeaturesDiv">
                            <h4 style={{ marginBottom: '3%' }}>{item?.title}</h4>
                            <img
                                style={{ width: '100%' }}
                                src={item?.img}
                            />
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
}

export default Portfolio;