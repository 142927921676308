const HeroSection = () => {

    return (
        <>
            <section class="row heroSec" id="home">
                <div class="col-lg-12 heroDiv">
                    <p style={{ color: 'white', opacity: '80%' }}>WEB AND APP DEVELOPMENT SERVICES</p>
                    <center>
                        <h2 class="heroHeading" style={{ opacity: '100%', color: 'white' }}>Get your Business Website or Mobile App build quickly with <font class="gradientText">Zeegens
                        </font> <br />
                            <font style={{ color: 'white', opacity: '60%' }}>No-Stress Web Development</font>
                        </h2>
                        <p class="heroPara">Zeegens: Where developers dream, think, and innovate. We're passionate about web
                            development, delivering exceptional results for our clients. Join us now!
                        </p>
                    </center>
                    <a href="#process"><button class="csBtn" style={{ color: 'black' }}>HOW IT WORKS</button></a>
                </div>
            </section>

        </>
    );
}

export default HeroSection;