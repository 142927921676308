import React, { useState } from 'react';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const WhyChooseUs = () => {

    return (
        <>
            <section class="row featuresSec" id="features" style={{ opacity: '100%', color: 'white' }}>
                <div class="row">
                    <center>
                        <h2 class="csHeading">Why choose
                            <font class="gradientText"> Zeegens.</font> Your Gateway to Digital Success!
                        </h2>
                    </center>
                </div>
                <div class="col-lg-4">
                    <div>
                        <div class="subFeaturesDiv">
                            <div class="icon icon-1 featuresIconDiv"><AiOutlineThunderbolt style={{ color: 'black', fontSize: 25 }} /></div>
                            <h4>Lightning-Fast Website</h4>
                            <p style={{ opacity: '70%', margin: 0 }}>Our web development expertise ensures lightning-fast loading
                                times, offering your visitors a seamless and engaging user experience. Speed up your website
                                with Zeegens.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <div class="subFeaturesDiv">
                            <div class="icon icon-3 featuresIconDiv"><BiSupport style={{ color: 'black', fontSize: 25 }} /></div>
                            <h4>Ongoing Support</h4>
                            <p style={{ opacity: '70%', margin: 0 }}>Our commitment doesn't end with a website launch. We offer
                                continuous support and updates to keep your website thriving and up-to-date.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <div class="subFeaturesDiv">
                            <div class="icon icon-4 featuresIconDiv"><IoIosCheckmarkCircle style={{ color: 'black', fontSize: 25 }} /></div>
                            <h4>Tailored Solutions</h4>
                            <p style={{ opacity: '70%', margin: 0 }}>We don't believe in one-size-fits-all. Our team crafts
                                customized websites that align perfectly with your unique brand identity and business goals.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WhyChooseUs;
